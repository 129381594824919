import { ICardMini, ILabel } from "@/shared/types/types";
export const generateCardLabels = (card: ICardMini) => {
  let topLabels: ILabel[] = [];
  let bottomLabel: ILabel | null = null;
  const extractNewLabel = () => {
    if (topLabels.length > 3 && topLabels.find(item => item.text === "Новинка")) {
      topLabels = topLabels.filter(item => item.text !== "Новинка");
    }
  };
  if (card.labels?.first_weapon) {
    topLabels.push({
      text: "Первое оружие",
      color: "green"
    });
  }
  if (card.product_license && card.product_license) {
    topLabels.push({
      text: "Лицензионный товар",
      color: "beige"
    });
  }
  if (card.labels && card.labels.new) {
    topLabels.push({
      text: "Новинка",
      color: "green"
    });
  }
  if (card.price > 500) {
    if (card.discount_percent > 10 && card.discount_percent <= 20) {
      topLabels.push({
        text: `-${card.discount_percent}%`,
        color: "yellow"
      });
    } else if (card.discount_percent > 20 && card.discount_percent <= 30) {
      topLabels.push({
        text: "Скидка больше 20%",
        color: "light-yellow"
      });
    } else if (card.discount_percent > 30 && card.discount_percent <= 40) {
      topLabels.push({
        text: "Скидка больше 30%",
        color: "middle-yellow"
      });
    } else if (card.discount_percent > 40) {
      topLabels.push({
        text: "Скидка больше 40%",
        color: "bright-yellow"
      });
    }
  }
  if (!card.available) {
    bottomLabel = {
      text: "Недоступно",
      color: "gray"
    };
    extractNewLabel();
  } else if (card.available && (card.diff_pack ? card.available_count <= card.diff_pack.value * 10 : card.available_count <= 10)) {
    bottomLabel = {
      text: "Осталось мало",
      color: "gray-red",
      variant: "small"
    };
    extractNewLabel();
  } else if (topLabels.length > 3) {
    let lastLabel = topLabels.pop();
    lastLabel ? bottomLabel = lastLabel : bottomLabel = null;
  }
  return {
    topLabels,
    bottomLabel
  };
};
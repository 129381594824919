import styles from "./index.module.scss";
import { FC, useEffect, useState } from "react";
import { ICardMini, ILabel } from "@/shared/types/types";
import Label from "@/shared/components/Label";
import { generateCardLabels } from "@/utils/functions/generateCardLabels";
interface ILabels {
  card: ICardMini;
}
export const Labels: FC<ILabels> = props => {
  const {
    card
  } = props;
  const {
    topLabels,
    bottomLabel
  } = generateCardLabels(card);
  return (topLabels || bottomLabel) && <div className={styles.labelWrap}>
        <div className={styles.top}>
          {topLabels.length > 0 && topLabels.map((label, i) => <Label key={i} text={label.text} color={label.color} />)}
        </div>
        <div className={styles.bottom}>
          {bottomLabel && <Label text={bottomLabel.text} color={bottomLabel.color} variant={bottomLabel.variant} />}
        </div>
      </div>;
};
export default Labels;
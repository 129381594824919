import clsx from "clsx";
import styles from "./index.module.scss";
import { Icon } from "@/shared/components/Icon";
import React from "react";
import { ILabelColor } from "@/shared/types/types";
interface ILabel extends React.ComponentProps<"p"> {
  text: string;
  color: ILabelColor;
  variant?: "default" | "border" | "small";
  className?: string;
  icon?: string;
  iconClassName?: string;
}
const Label = (props: ILabel) => {
  const {
    color = "green",
    text,
    className,
    icon,
    iconClassName,
    variant = "default"
  } = props;
  return <p className={clsx(styles.label, styles[color], styles[variant], className)} data-sentry-component="Label" data-sentry-source-file="index.tsx">
      {icon ? <Icon name={icon} className={clsx(styles.icon, iconClassName)} /> : null}
      {text}
    </p>;
};
export default Label;